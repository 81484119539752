import React, { useEffect } from "react";
import { navigate } from "gatsby";

const CitibanamexRedirect = () => {
  useEffect(() => {
    navigate("/citibanamex2022", { replace: true });
  });

  return null;
};

export default CitibanamexRedirect;
